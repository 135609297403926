<template>
    <v-list-item>
        <v-list-item-icon >
            <v-icon v-text="kategori.ikon" color="background lighten-4"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title v-text="kategori.navn"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
            <v-list-item-action-text>{{betalerForKategoriAvrundet | formatNumber}} kr</v-list-item-action-text>
        </v-list-item-action>
    </v-list-item>
</template>
<script>
    import Statsbudsjett from "@/assets/statsbudsjett.json"

    export default {
        props: {
            kategori: Object
        },
        computed: {
            skattKroner() {
                return this.$store.getters.inntekt * 1.14 * this.$store.getters.skatteprosent / 100;
            },
            betalerForKategoriAvrundet() {
                return Math.round(this.skattKroner * this.kategori.andelAvStatsbudsjett / Statsbudsjett.utgifterTotalt)
            }
        },
    }
</script>