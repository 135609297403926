<template>
    <v-navigation-drawer app right clipped floating color="background" v-model="show" bottom>
        <v-container class="pa-4">
            <v-subheader class="pa-0">Beregning</v-subheader>
            <p class="caption">
                    Skatteberegningen er et estimat. Beregningen antar en vanlig
                    mengde lån og forbruk av avgiftsbelagte varer som bensin, alkohol, strøm, mat etc.
                    <br>
                    Et helt korrekt beløp kommer an på hvordan forbruket av forskjellige varer og tjenester er, hvor i
                    landet man bor, og noen flere ting.
                    <br><br>Hovedpoenget med denne siden er å presentere kort og konkret at selv om nevnte tjenester
                    ofte omtales som gratis, så betaler de aller fleste en betydelig sum hvert år.
                    <br><br>
                    Satsene for de forskjellige eksemplene er tatt fra SSB sin oversikt
                    <a href="https://www.ssb.no/offentlig-sektor/faktaside/slik-brukes-skattepengene">
                        "Slik brukes skattepengene"
                    </a>
            </p>
        </v-container>
    </v-navigation-drawer>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        computed: {
            show: {
                get() {
                    return this.$store.getters.informationDrawer
                },
                set(val) {
                    this.$store.commit('setInformationDrawer', val)
                }
            }
        },
    }

</script>
<style scoped></style>