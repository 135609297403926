<template>
    <v-app :style="{background: $vuetify.theme.themes[theme].background}">
        <AppBar></AppBar>
        <UserInputDrawer></UserInputDrawer>
        <InformationDrawer></InformationDrawer>
        <ShareDrawer></ShareDrawer>
        <v-content>
            <router-view/>
        </v-content>
        <Footer></Footer>
        <v-snackbar
                v-model="snackbar"
                bottom
                color="background darken-1"
                :timeout=4000
                @click="$store.commit('setUserInputDrawer', true); snackbar = false"
        >
            Endre din inntekt via menyen
            <v-icon fab>mdi-settings</v-icon>
        </v-snackbar>

        <ShareFab v-if="$vuetify.breakpoint.lgAndUp"></ShareFab>
        <BottomNavigation v-if="$vuetify.breakpoint.mdAndDown"></BottomNavigation>
    </v-app>
</template>

<script>
    import AppBar from "./components/AppBar"
    import UserInputDrawer from "./components/UserInputDrawer"
    import InformationDrawer from "./components/InformationDrawer"
    import Footer from "./components/Footer"
    import ShareFab from "./components/ShareFab"
    import BottomNavigation from "./components/BottomNavigation";
    import ShareDrawer from "./components/ShareDrawer";

    export default {
        name: 'App',
        mounted() {
            // todo kan evt aktivere denne igjen senere
            if (this.$vuetify.breakpoint.mdAndDown) {
                this.snackbar = false
            } else {
                this.snackbar = false
            }
        },
        data: () => ({
            snackbar: false
        }),
        computed: {
            theme() {
                return this.$vuetify.theme.dark ? 'dark' : 'light'
            }
        },
        components: {
            ShareDrawer,
            BottomNavigation,
            AppBar,
            UserInputDrawer,
            InformationDrawer,
            Footer,
            ShareFab
        },
    };
</script>
<style>

    ::-webkit-scrollbar {
        width: 0.4em;
    }

    ::-webkit-scrollbar-track {
        background: var(--v-background-base)
    }

    ::-webkit-scrollbar-thumb {
        background: var(--v-background-lighten1);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--v-background-lighten2);
    }
</style>
