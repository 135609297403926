<template>
    <v-container>
        <v-row class="justify-center">
            <h1 :class=" $vuetify.breakpoint.lgAndUp ? 'display-3 font-weight-light' : 'display-2 font-weight-light'">
                {{skattKroner | formatNumber }} kr
            </h1>
        </v-row>
        <v-row class="justify-center pl-4 pr-4">
            <h2 class="title font-weight-light">betaler du totalt i skatter og avgifter &ndash; inkludert arbeidsgiveravgift</h2>
        </v-row>
        <v-row class="justify-center">
            <h2 class="title font-weight-light">dette utgjør {{$store.getters.skatteprosent}}%</h2>
        </v-row>

        <v-row v-if="$vuetify.breakpoint.smAndUp" align="center" no-gutters>
            <v-col sm="6" md="6" lg="4" xl="3" v-for="kategori in utgiftskategorier" :key="kategori.navn">
                <UtgiftskategoriCard :kategori="kategori"></UtgiftskategoriCard>
            </v-col>
        </v-row>

        <v-divider v-if="$vuetify.breakpoint.xsOnly" class="mt-4 mb-1 ml-10 mr-10 divider"></v-divider>

        <v-list v-if="$vuetify.breakpoint.xsOnly" color="transparent" disabled class="mb-6">
            <UtgiftskategoriListItem
                    v-for="kategori in utgiftskategorier"
                    :key="kategori.navn"
                    :kategori="kategori"
            >
            </UtgiftskategoriListItem>
        </v-list>
    </v-container>
</template>

<script>
    // @ is an alias to /src
    import Statsbudsjett from "@/assets/statsbudsjett.json"
    import UtgiftskategoriCard from "@/components/UtgiftskategoriCard";
    import UtgiftskategoriListItem from "../components/UtgiftskategoriListItem";

    export default {
        name: 'home',
        data: () => ({
            utgiftskategorier: Statsbudsjett.utgiftskategorier,
        }),
        computed: {
            skattKroner() {
                return this.$store.getters.inntekt * 1.14 * this.$store.getters.skatteprosent / 100;
            }
        },
        components: {UtgiftskategoriListItem, UtgiftskategoriCard}
    }
</script>
<style scoped>
    .divider {
        /*border-color: var(--v-background-lighten1) !important;*/
    }
</style>