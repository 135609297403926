<template>
    <v-app-bar app flat :dense="$vuetify.breakpoint.smAndDown" clipped-left clipped-right :color="$vuetify.breakpoint.smAndUp ? 'background' : 'background darken-1'">
        <v-spacer></v-spacer>
        <v-toolbar-title class="headline text-uppercase">
            <span class="font-weight-light">er det </span>
            <span>gratis</span>
            <span class="font-weight-light">?</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-app-bar>
</template>
<script>
    export default {
        data: () => ({}),
        computed: {
        },
    }
</script>
<style scoped>
</style>