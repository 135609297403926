<template>
    <v-bottom-sheet v-model="show" max-width="600">
        <v-card color="background" class="text-center">
            <v-btn fab width="70" height="70" color="#4267B2" class="ma-3 elevation-0"
                   @click="openInWindow(facebookShareUrl)">
                <v-icon size="40" color="white">mdi-facebook</v-icon>
            </v-btn>
            <v-btn fab width="70" height="70" color="#00C6FF" class="ma-3 elevation-0"
                   @click="openInWindow(twitterShareUrl)">
                <v-icon size="40" color="white">mdi-twitter</v-icon>
            </v-btn>
            <v-btn fab width="70" height="70" color="#2867B2" class="ma-3 elevation-0"
                   @click="openInWindow(linkedinShareUrl)">
                <v-icon size="40" color="white">mdi-linkedin</v-icon>
            </v-btn>
        </v-card>
    </v-bottom-sheet>
</template>
<script>
    export default {
        computed: {
            show: {
                get() {
                    return this.$store.getters.shareDrawer
                },
                set(val) {
                    this.$store.commit('setShareDrawer', val)
                }
            }
        },
    }
</script>
<style scoped>

</style>