<template>
    <v-footer v-intersect="onIntersect" :color="$vuetify.breakpoint.smAndUp ? 'background' : 'background lighten-1'" app
              absolute style="z-index: 1">
        <v-row class="justify-center align-center text-center pa-2">
            <v-window v-model="currentQuote" class="flat" style="width: 100%">
                <v-window-item v-for="n in quotes.length-1" :key="n">
                    <v-row dense class="justify-center align-center " style="font-size: 1.2em">
                        {{quotes[n].quote}}
                    </v-row>
                    <v-row dense class="justify-center align-center " color="secondary" style="font-size: 0.8em">
                        {{quotes[n].by}}
                    </v-row>
                    <!--<v-row dense class="justify-center align-center pa-1" color="secondary">-->
                        <!--<v-btn text color="primary">del sitat</v-btn>-->
                    <!--</v-row>-->
                </v-window-item>
            </v-window>
        </v-row>
    </v-footer>
</template>
<script>
    import Quotes from "../assets/liberalisme-sitater.json"

    export default {
        data() {
            return {
                quotes: Quotes,
                currentQuote: 0,
                autorun: true
            }
        },
        mounted() {
            setInterval(() => {
                if (!this.autorun) return;
                if (++this.currentQuote >= this.quotes.length) this.currentQuote = 0
            }, 10000)
        },
        computed: {
            fontSize() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return '1em';
                    case 'sm':
                        return '1em';
                    case 'md':
                        return '2em';
                    case 'lg':
                        return '7em';
                    case 'xl':
                        return '8em';
                    default:
                        return "4em"
                }
            },
        },
        methods: {
            onIntersect(entries) {
                // More information about these options
                // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
                const isIntersecting = entries[0].isIntersecting
                this.$store.commit('setFooterVisible', isIntersecting)
            },
        },
    }
</script>
<style scoped>

</style>