<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <span>
        <v-scale-transition appear>
            <v-btn v-if="showSettingsFab" fab bottom fixed color="primary"
                   :style="'left: ' + fabCenterPosition + 'px'"
                   @click.stop="$store.commit('setUserInputDrawer', !$store.getters.userInputDrawer)"
            >
                <v-icon>mdi-settings</v-icon>
            </v-btn>
        </v-scale-transition>
    <v-bottom-navigation
            fixed
            background-color="background darken-1"
            color="white"
            app
            class="elevation-0"
            z-index="9998"
    >

        <v-btn @click.stop="$store.commit('setInformationDrawer', !$store.getters.informationDrawer)">
            <v-icon color="white">mdi-information-outline</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn v-if="navigatorShare" @click="navigatorShareClicked()">
            <v-icon>mdi-share-variant</v-icon>
        </v-btn>

        <v-btn v-else @click.stop="$store.commit('setShareDrawer', !$store.getters.shareDrawer)">
            <v-icon color="white">mdi-share-variant</v-icon>
        </v-btn>

    </v-bottom-navigation>
        </span>
</template>
<script>
    export default {
        data: () => ({
            brrap: false,
            showShareSheet: false,
            facebookShareUrl: encodeURI("https://www.facebook.com/sharer.php?u=https://erdetgratis.no/"),
            twitterShareUrl: encodeURI("https://twitter.com/intent/tweet?url=https://erdetgratis.no/&hashtags=erdetgratis?"),
            linkedinShareUrl: encodeURI("https://www.linkedin.com/shareArticle?mini=true&url=https://erdetgratis.no")
        }),
        computed: {
            fabCenterPosition() {
                return window.innerWidth / 2 - 56 / 2
            },
            showSettingsFab() {
                return this.$vuetify.breakpoint.mdAndDown && !this.$store.getters.footerVisible
            },
            navigatorShare() {
                return navigator.share
            }
        },
        methods: {
            navigatorShareClicked() {
                const title = "Er det gratis?";
                const url = "https://www.erdetgratis.no";

                navigator.share({
                    title,
                    url
                })
                // .then(() => {
                // })
                // .catch(() => { //err
                // });
            },
            openInWindow(url) {
                var left = (screen.width - 570) / 2;
                var top = (screen.height - 570) / 2;
                var params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left;
                window.open(url, "NewWindow", params);
            }
        }
    }
</script>
<style scoped>
    .v-item-group.v-bottom-navigation--fixed {
        z-index: 3 !important;
    }

    .bounce-enter-active {
        /*transition-delay: 1.5s;*/
        animation: bounce-in .5s;
    }

    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }

    .bounce-enter, .bounce-leave-to {
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0.0);
        }
        50% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }
</style>