import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueAnalytics from 'vue-analytics'

Vue.filter('formatNumber', function (value) {
    const rounded = Math.round(value / 1000) * 1000;
    return rounded.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, " ");
});

Vue.use(VueAnalytics, {
    id: 'UA-150949549-1',
    router
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
