import Vue from 'vue';
import Vuetify, {
    VNavigationDrawer,
    VBottomNavigation,
    VBanner,
    VScaleTransition,
} from 'vuetify/lib';
import {Intersect} from 'vuetify/lib/directives/intersect';

Vue.use(Vuetify, {
    components: {
        VNavigationDrawer,
        VBottomNavigation,
        VBanner,
        VScaleTransition
    },
    directives: {
        Intersect
    }
});

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            dark: {
                background: '#662E9B',
                primary: '#50C5B7',
                secondary: '#CC5A71',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            },
        },
        dark: true
    },
    icons: {
        iconfont: 'mdi',
    },
});
