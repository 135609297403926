<template>
    <v-card flat class="pa-4 ma-2 text-center" color="background lighten-1" height="12em" style="border-radius: 0.5em">
        <v-icon size="3em" color="background lighten-4">{{kategori.ikon}}</v-icon>
        <h2 class="text-center headline">{{kategori.navn}}</h2>
        <v-card-text>
            du betaler
            ca. <h2 class="font-weight-light">{{ betalerForKategoriAvrundet | formatNumber }} kr</h2>
        </v-card-text>
    </v-card>
</template>
<script>
    import Statsbudsjett from "@/assets/statsbudsjett.json"

    export default {
        props: {
            kategori: Object
        },
        computed: {
            skattKroner() {
                return this.$store.getters.inntekt * 1.14 * this.$store.getters.skatteprosent / 100;
            },
            betalerForKategoriAvrundet() {
                return Math.round(this.skattKroner * this.kategori.andelAvStatsbudsjett / Statsbudsjett.utgifterTotalt)
            }
        },
        methods: {}

    }
</script>
<style scoped>

</style>