import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        footerVisible: false,
        userInputDrawer: null,
        informationDrawer: null,
        shareDrawer: null,
        input: { // brukers input
            inntekt: 600000,
            avgifter: {
                alkohol: true,
                bil: true,
                finansskatt: false,
            },
        },
        output: { // beregnet basert på brukers input
            skatteprosent: 60
        },
    },
    mutations: {
        setUserInputDrawer(state, show) {
            state.userInputDrawer = show
        },
        setInformationDrawer(state, show) {
            state.informationDrawer = show
        },
        setShareDrawer(state, show) {
            state.shareDrawer = show
        },
        setInntekt(state, inntekt) {
            state.input.inntekt = inntekt
        },
        setSkatteprosent(state, prosent) {
            state.output.skatteprosent = prosent
        },
        setFooterVisible(state, visible) {
            state.footerVisible = visible
        }
    },
    actions: {},
    modules: {},
    getters: {
        userInputDrawer: state => state.userInputDrawer,
        informationDrawer: state => state.informationDrawer,
        shareDrawer: state => state.shareDrawer,
        inntekt: state => state.input.inntekt,
        avgifter: state => state.input.avgifter,
        skatteprosent: state => state.output.skatteprosent,
        statsbudsjettUtgiftskategorier: state => state.statsbudsjettUtgiftskategorier,
        footerVisible: state => state.footerVisible,
    }
})
