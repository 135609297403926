/* eslint-disable */
function CalculateTaxPercentage(gross) {
    const tax = CalculateTax(gross);
    return Math.round(tax / (gross*1.14) * 100);
}

function CalculateTax(gross) {
    var tax, payrolltax, totaltax;
    var personinntekt, alminneliginntekt, skattpaalminneliginntekt;
    var personfradrag, minstefradrag, foreldrefradrag, trygdeavgift, firmaut;
    var trinn1, trinn2, trinn3, trinn4;
    var usagetax, renteutgifter;

    foreldrefradrag = 0;
    foreldrefradrag = 10000;
    renteutgifter = 42188 * gross / 570000;

    personfradrag = 56550;
    minstefradrag = 100800;

    payrolltax = gross * 0.14;

    firmaut = gross + payrolltax;

    personinntekt = gross;
    alminneliginntekt = gross - minstefradrag - renteutgifter - foreldrefradrag;

    trygdeavgift = 0.082 * personinntekt;

    skattpaalminneliginntekt = Math.max((alminneliginntekt - personfradrag) * 0.22, 0);

    trinn1 = Math.max((Math.min(245650, personinntekt) - 174500) * 0.019, 0);
    trinn2 = Math.max((Math.min(617500, personinntekt) - 245650) * 0.042, 0);
    trinn3 = Math.max((Math.min(964800, personinntekt) - 617500) * 0.132, 0);
    trinn4 = Math.max((personinntekt - 964800) * 0.162, 0);

    usagetax = CalculateUsageTax(gross) * 12;
    totaltax = payrolltax + trygdeavgift + skattpaalminneliginntekt + trinn1 + trinn2 + trinn3 + trinn4 + usagetax;

    return totaltax;
}

function CalculateUsageTax(gross) {
    var tax;

    tax = gross / 12 / 3 * 0.25;
    /* 1/3 gar til diverse, 25% mva */
    tax = tax;
    /* 1/3 gar til avgiftsfritt */
    tax = gross / 12 / 3 * 0.45;
    /* 1/3 gar til avgift + mva */
    /*
          tax = tax + 2000d/2d*0.40; // strom, vanlig med 1000 - forbruksavgift + mva
          tax = tax + 3039d/2d/12d; // TV-lisens pa to personer
          tax = tax + 200d; // Vann og kloak
          tax = tax + 500*0.25d; // TV mva
          tax = tax + 600d; // BOM
          tax = tax + 2800d * 0.59; // Bensin
          tax = tax + 3000d * 0.5 + 0.25; // Bil - avgifter + mva
          tax = tax + 2000d * 0.25; // Bil - avgifter + mva
           */

    return tax;
}

function test() {
    var tax;

    tax = CalculateTax(563066);

    /*System.out.println(tax); */
    return 0;
}

export default {
    CalculateTaxPercentage
}
