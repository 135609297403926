<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-navigation-drawer app clipped floating color="background" v-model="show" bottom>
        <v-container class="pa-4">
            <v-subheader class="pa-0">Din bruttolønn</v-subheader>
            <!--<div class="body-2" style="color: rgba(255, 255, 255, 0.7); ">din inntekt</div>-->
            <div class="display-1 ">{{inntekt | formatNumber}}</div>
            <v-slider v-model="inntekt" step="50000" max="1300000" min="350000"></v-slider>
        </v-container>

        <!--<v-list subheader two-line flat shaped >-->
            <!--<v-subheader>relevante avgifter (kommer snart) </v-subheader>-->

            <!--<v-list-item-group v-model="settings" multiple>-->
                <!--<v-list-item v-for="avgift in avgifter" :key="avgift" @click="avgift = !avgift"-->
                             <!--:style="avgift.valgt ? 'background-color: secondary' : ''">-->
                    <!--<v-list-item-action >-->
                        <!--<v-checkbox-->
                                <!--v-model="avgift.valgt"-->
                                <!--color="primary"-->
                                <!--@click="avgift.valgt = !avgift.valgt"-->
                        <!--&gt;</v-checkbox>-->
                    <!--</v-list-item-action>-->
                    <!--<v-list-item-content>-->
                        <!--<v-list-item-title>{{avgift}}</v-list-item-title>-->
                        <!--&lt;!&ndash;<v-list-item-subtitle>Undertekst</v-list-item-subtitle>&ndash;&gt;-->
                    <!--</v-list-item-content>-->
                <!--</v-list-item>-->
            <!--</v-list-item-group>-->
        <!--</v-list>-->
    </v-navigation-drawer>
</template>
<script>
    import Skatteberegning from "../assets/skatteberegning"

    export default {
        data() {
            return {
                dialog: false,
                settings: [],
                avgifter: Object.keys(this.$store.getters.avgifter)
            }
        },
        computed: {
            show: {
                get() {
                    return this.$store.getters.userInputDrawer
                },
                set(val) {
                    this.$store.commit('setUserInputDrawer', val)
                }
            },
            inntekt: {
                get() {
                    return this.$store.getters.inntekt
                },
                set(val) {
                    this.$store.commit('setSkatteprosent', Skatteberegning.CalculateTaxPercentage(val))
                    this.$store.commit('setInntekt', val)
                }
            },
        },
        methods: {
            // inntektEndret: function (value){
            //     console.log(value);
            // }
        },
        mounted() {
            if (this.$vuetify.breakpoint.lgAndUp) this.$store.commit('setUserInputDrawer', true)
        }
    }

</script>
<style scoped></style>