<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-scale-transition mode="out-in">
        <v-btn v-if="navigatorShare" fab right bottom fixed color="primary" @click="navigatorShareClicked()">
            <v-icon>mdi-share-variant</v-icon>
        </v-btn>
        <v-speed-dial
                v-else
                v-model="speedDial"
                right bottom fixed
                direction="top"
        >
            <template v-slot:activator>
                <v-btn
                        v-model="speedDial"
                        color="primary"
                        fab
                >
                    <v-icon v-if="speedDial">mdi-close</v-icon>
                    <v-icon v-else>mdi-share-variant</v-icon>
                </v-btn>
            </template>
            <v-btn fab width="70" height="70" color="#4267B2" class="ma-3 elevation-0"
                   @click="openInWindow(facebookShareUrl)">
                <v-icon size="40" color="white">mdi-facebook</v-icon>
            </v-btn>
            <v-btn fab width="70" height="70" color="#00C6FF" class="ma-3 elevation-0"
                   @click="openInWindow(twitterShareUrl)">
                <v-icon size="40" color="white">mdi-twitter</v-icon>
            </v-btn>
            <v-btn fab width="70" height="70" color="#2867B2" class="ma-3 elevation-0"
                   @click="openInWindow(linkedinShareUrl)">
                <v-icon size="40" color="white">mdi-linkedin</v-icon>
            </v-btn>
        </v-speed-dial>
    </v-scale-transition>

</template>
<script>

    export default {
        data() {
            return {
                speedDial: false,
                facebookShareUrl: encodeURI("https://www.facebook.com/sharer.php?u=https://erdetgratis.no/"),
                twitterShareUrl: encodeURI("https://twitter.com/intent/tweet?url=https://erdetgratis.no/&hashtags=erdetgratis?"),
                linkedinShareUrl: encodeURI("https://www.linkedin.com/shareArticle?mini=true&url=https://erdetgratis.no")
            }
        },
        computed: {
            navigatorShare() {
                return navigator.share
            }
        },
        methods: {
            navigatorShareClicked() {
                const title = "Er det gratis?";
                const url = "https://www.erdetgratis.no";

                navigator.share({
                    title,
                    url
                })
                // .then(() => {
                // })
                // .catch(() => { //err
                // });
            },
            openInWindow(url) {
                var left = (screen.width - 570) / 2;
                var top = (screen.height - 570) / 2;
                var params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left;
                window.open(url, "NewWindow", params);
            }
        }
    }
</script>